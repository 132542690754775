import slugify from "slugify";

import { ensureLeadingSlash } from "~utils/path_utils/ensureLeadingSlash";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";

import type { TransformedLinkPropsShape } from ".";
import type { StoryblokFieldLink } from "~types/storyblok.types";

/** Gets link props for a storyblok asset download. */
export function transformLinkPropsAsset(
  link: StoryblokFieldLink
): TransformedLinkPropsShape {
  let internalUrl = link.story?.url || link.href || undefined;
  internalUrl = internalUrl && ensureLeadingSlash(internalUrl);
  internalUrl = internalUrl && removeDefaultLanguagePrefix(internalUrl);

  if (internalUrl) {
    const sanitisedFilename = slugify(internalUrl, {
      lower: true,
      strict: true,
    });

    return {
      target: "_blank",
      to: internalUrl,
      download: sanitisedFilename,
      linkType: "asset",
    };
  }

  return {};
}
