/* eslint-disable import/group-exports */

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

/* eslint-disable max-classes-per-file, no-underscore-dangle */
import React from "react";

// Custom error class used to filter error types
export class StoryblokError extends Error {}

let iframeReady;

interface HandleStoryblokErrorsProps {
  children: React.ReactNode;
}

class HandleStoryblokErrors extends React.Component<HandleStoryblokErrorsProps> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return error instanceof StoryblokError ? { error } : {};
  }

  componentDidMount() {
    if (window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__) {
      iframeReady = window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.iframeReady;
    }
  }

  componentDidCatch(e) {
    if (e instanceof StoryblokError && iframeReady) {
      window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.iframeReady =
        function customIframeReady() {
          iframeReady();
          if (e instanceof StoryblokError) {
            const overlay = document.querySelector("iframe:last-child");
            if (overlay) {
              overlay.parentNode.removeChild(overlay);
            }
          }
        };
    }
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error.message}</h1>;
    }

    return this.props.children;
  }
}

export function withHandleStoryblokErrors(Component) {
  return function (props) {
    return (
      <HandleStoryblokErrors>
        <Component {...props} />
      </HandleStoryblokErrors>
    );
  };
}
