import type { CardProps } from "~components/Card";
import type { VariantCardAppearanceEnum } from "~components/Card/styles.css";

const SUB_COMPONENT_MAP: Record<
  VariantCardAppearanceEnum,
  CardProps["subComponents"]
> = {
  customerStory: ["title", "prefix", "logo", "image", "description"],
  event: ["title", "date", "logo", "description", "icon"],
  report: ["prefix", "title", "logosList", "icon", "image"],
  blogPost: ["title", "date", "image", "description", "logo"],
  generic: ["title", "image", "description"],
  video: ["title", "date", "logo", "image", "description"],
  featured: ["title", "image", "description", "logo", "ctaText"],
  contactUs: ["title", "description", "ctaText"],
  people: ["title", "description", "logo", "image"],
};

export const getCardSubComponents = (appearance: VariantCardAppearanceEnum) => {
  return appearance
    ? SUB_COMPONENT_MAP[appearance]
    : SUB_COMPONENT_MAP.blogPost;
};
