import type { StoryblokChildren } from "~types/storyblok.types";

export const getHeroAndHeader = (children?: StoryblokChildren) => {
  const hero = children?.find((section) => {
    return section?.id === "hero";
  });
  const header = children?.find((section) => {
    return section?.id === "header";
  });
  const content = children?.filter((section) => {
    return section?.id !== "hero" && section?.id !== "header";
  });

  return { hero, header, content };
};
