import { ensureLeadingSlash } from "~utils/path_utils/ensureLeadingSlash";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";

import type { TransformedLinkPropsShape } from ".";
import type { StoryblokFieldLink } from "~types/storyblok.types";

/** Gets link props for an internal link configured in storyblok. */
export function transformLinkPropsInternal(
  link: StoryblokFieldLink
): TransformedLinkPropsShape {
  let internalUrl: string = link.story?.url || link.cached_url || "";

  internalUrl = internalUrl.startsWith("#")
    ? internalUrl
    : ensureLeadingSlash(internalUrl);
  internalUrl = removeDefaultLanguagePrefix(internalUrl);

  // Set up anchor
  if (link.anchor) {
    internalUrl = `${internalUrl}#${link.anchor}`;
  }

  return {
    target: "_self",
    to: internalUrl,
    linkType: "internalLink",
    state: link.state,
  };
}
