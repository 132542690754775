import type { TemplateGenericPageStory } from ".";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export const getHeaderBackgroundColor = ({
  children,
}: TemplateGenericPageStory): VariantBackgroundColorEnum => {
  const [firstChild] = children || [];

  if (!firstChild) return "background_white";

  const { background } = firstChild || {};

  if (!background) return "background_white";

  return background;
};
