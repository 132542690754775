import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { CardProps } from "~components/Card";
import type { StoryblokEntry } from "~types/storyblok.types";

/**
 * In the visual editor, relations arrive as an object of storyblok entries,
 * directly from the storyblok API
 */
export function transformStoryblokEntryApiToCard(
  storyblokEntry: StoryblokEntry
): CardProps {
  const { content: entryContent, full_slug } = storyblokEntry || {};
  const {
    title,
    description,
    brandIcon,
    cover,
    date,
    linkToExternalPage,
    location,
    logo,
    logos,
    pageMetadata,
    theme,
  } = entryContent || {};

  const {
    ctaText,
    description: metaDescription,
    title: metaTitle,
    prefix,
  } = pageMetadata || {};
  const link = createInternalLink(full_slug);

  return {
    brandIcon,
    prefix,
    ctaText,
    date,
    description: description || metaDescription,
    image: cover,
    link: linkToExternalPage || link,
    location,
    logo,
    logos,
    title: title || metaTitle,
    theme,
  };
}
