import type { ContentTopic } from "~types/storyblok.types";

export function getTopicLabels(
  cardTopics: Array<string> | undefined | null,
  contextTopics: Array<ContentTopic> | undefined
): Array<string> | undefined | null {
  return cardTopics?.map((topicKey) => {
    const { label = "" } =
      contextTopics?.find((topic) => {
        return topic.value === topicKey;
      }) || {};

    return label;
  });
}
