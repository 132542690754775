import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { ContentSidebarHubspotForm } from "~components/ContentSidebarHubspotFormDialog";
import { EmbedWistia } from "~components/EmbedWistia";
import { HorizontalDivider } from "~components/HorizontalDivider";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { RichTextBlokResolver } from "~components/StoryblokRichText/RichTextBlokResolver";
import { TableOfContents } from "~components/TableOfContents";
import { VideoYoutube } from "~components/VideoYoutube";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { AnchorJsContextProvider } from "~context/AnchorJsContext";

import type { EmbedWistiaProps } from "~components/EmbedWistia";
import type { IconBrandProps } from "~components/IconBrand";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { VideoYoutubeProps } from "~components/VideoYoutube";
// import { LayoutSection } from "~sections/LayoutSection";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { GlobalPageProps } from "~types/global.types";
import type {
  CommonContentPageStoryProps,
  StoryblokBlok,
} from "~types/storyblok.types";
import type { DispatchConversionEventArgs } from "~utils/analytics/dispatchConversionEvent";

// import { OnlineReportChapters } from "./OnlineReportChapters";

export { Head } from "~components/Head";

export type OnlineReportStory = CommonContentPageStoryProps & {
  brandIcon?: IconBrandProps["icon"];
  date?: string;
  hubspotFormCta?: string;
  hubspotFormId?: string;
  logos?: Array<SvgLogoProps["svgLogo"]>;
  relatedItems?: Array<StoryblokBlok & RelationsCardsProps>;
  youtubeVideo?: Array<StoryblokBlok & VideoYoutubeProps>;
  wistiaVideo?: Array<StoryblokBlok & EmbedWistiaProps>;

  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;
};

export type TemplateOnlineReportProps = GlobalPageProps<OnlineReportStory>;

export default function TemplateOnlineReport(props: TemplateOnlineReportProps) {
  const { story, pageContext } = usePageData<TemplateOnlineReportProps>(props);

  const {
    title,
    bodyRichText,
    hubspotFormCta,
    hubspotFormId,
    // relatedItems,
    youtubeVideo: youtubeVideBlokArray,
    wistiaVideo: wistiaVideBlokArray,
  } = story || {};

  const analyticsData: DispatchConversionEventArgs = {
    type: "content_report",
    cta: hubspotFormCta,
    component: "ContentSidebarHubspotForm",
  };

  // ensuring backwards compatibility for pages without new title field valued
  const { title: metaTitle } = usePageMetadata({ story }) || {};
  const { breadcrumbs } = pageContext || {};

  /** Template may include a blok array containing an embedded youtube video */
  const [youtubeVideoBlok] = youtubeVideBlokArray || [];
  const [wistiaVideoBlok] = wistiaVideBlokArray || [];

  return (
    <LayoutPage {...props}>
      <HeroContentPage breadcrumbs={breadcrumbs} title={title || metaTitle} />

      <AnchorJsContextProvider>
        <LayoutSidebar.Wrapper
          // isBorderRounded
          sidebarPositionDesktop="right"
          sidebarPositionMobile="top"
        >
          <LayoutSidebar.Primary id="content">
            {youtubeVideoBlok && (
              <>
                <VideoYoutube {...youtubeVideoBlok} />
                <HorizontalDivider />
              </>
            )}

            {wistiaVideoBlok && (
              <>
                <EmbedWistia {...wistiaVideoBlok} />
                <HorizontalDivider />
              </>
            )}

            {bodyRichText && (
              <StoryblokRichText
                text={bodyRichText}
                defaultBlokResolver={RichTextBlokResolver}
              />
            )}
          </LayoutSidebar.Primary>

          <LayoutSidebar.Secondary isSidebarSticky>
            <ContentSidebarHubspotForm
              ctaText={hubspotFormCta}
              hubspotFormId={hubspotFormId}
              analyticsData={analyticsData}
            />

            <TableOfContents />
          </LayoutSidebar.Secondary>
        </LayoutSidebar.Wrapper>
      </AnchorJsContextProvider>

      {/* <LayoutSection>
        <OnlineReportChapters relatedItems={relatedItems} />
      </LayoutSection> */}
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateOnlineReportQuery(
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]!
    # $relationsTags: [String]!

    $id: String
    $lang: String
    $languageRegex: String
  ) {
    ...pageContentStoryblokEntry
    ...global
  }
`;
