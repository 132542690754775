import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { CtaCardHubspotFormDialog } from "~components/CtaCardHubspotFormDialog";
import { StoryblokComponent } from "~components/StoryblokComponent";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import type { IconBrandProps } from "~components/IconBrand";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { GlobalPageProps } from "~types/global.types";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export type LandingPageStory = {
  brandIcon?: IconBrandProps["icon"];
  children?: Array<StoryblokBlok>;
  cover?: StoryblokFieldMedia;
  hubspotFormCta?: string;
  hubspotFormDescription?: string;
  hubspotFormId?: string;
  hubspotFormTitle?: string;
  logos?: Array<SvgLogoProps["svgLogo"]>;

  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;
};

export type TemplateLandingPageReportProps = GlobalPageProps<LandingPageStory>;

export { Head } from "~components/Head";
export default function TemplateLandingPageReport(
  props: TemplateLandingPageReportProps
) {
  const { story, pageContext } =
    usePageData<TemplateLandingPageReportProps>(props);

  // ensuring backwards compatibility for pages without new title field valued
  const { title: metaTitle } = usePageMetadata({ story }) || {};
  const { breadcrumbs } = pageContext || {};

  const {
    title,
    children,
    hubspotFormId,
    hubspotFormTitle,
    hubspotFormDescription,
    hubspotFormCta,
    cover,
    // relatedItems: relatedItemsBlokArray,
  } = story || {};

  const shouldRenderForm =
    hubspotFormId &&
    hubspotFormTitle &&
    hubspotFormDescription &&
    hubspotFormCta;

  return (
    <LayoutPage headerBackground="background_sand" {...props}>
      {/**
       * ----------------------------------------------------------------------------
       * Page hero
       * -------------------------------------------------------------------------------
       */}
      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title || metaTitle}
        cover={cover}
        coverAspectRatio="a4"
        background="background_sand"
      />

      <LayoutSidebar.Wrapper
        sidebarPositionDesktop="right"
        sidebarPositionMobile="bottom"
      >
        <LayoutSidebar.Primary
          display="flex"
          flexDirection="column"
          gap="sectionRowGap"
        >
          {Array.isArray(children) &&
            children.map((item) => {
              return <StoryblokComponent key={item._uid} {...item} />;
            })}
        </LayoutSidebar.Primary>

        {/**
         * --------------------------------------------
         * Sidebar content
         * -----------------------------------------------
         */}

        <LayoutSidebar.Secondary isSidebarSticky>
          {/* Form "download now" CTA button */}
          {shouldRenderForm && (
            <CtaCardHubspotFormDialog
              title={hubspotFormTitle}
              text={hubspotFormDescription}
              formId={hubspotFormId}
              buttonText={hubspotFormCta}
              conversionType="content_report"
              background="background_white"
            />
          )}
        </LayoutSidebar.Secondary>
      </LayoutSidebar.Wrapper>
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateLandingPageQuery(
    $id: String
    $languageRegex: String
    $lang: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...pageContentStoryblokEntry
    ...global
  }
`;
