import React from "react";

import loadable from "@loadable/component";
import clsx from "clsx";
import { graphql } from "gatsby";

import { global_backgroundImage } from "~styles/common/global_backgroundImage.css";
import { getSprinkles } from "~styles/getSprinkles.css";

import { usePageData } from "~hooks/use_page_data/use_page_data";

import { Box } from "~components/Box";
import { ButtonLink } from "~components/ButtonLink";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { VStack } from "~components/VStack";

import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSection } from "~sections/LayoutSection";

import type { ButtonLinkProps } from "~components/ButtonLink";
import type { GlobalPageProps } from "~types/global.types";
import type {
  StoryblokBlokArray,
  StoryblokFieldRichText,
} from "~types/storyblok.types";

const Background = loadable(() => {
  return import("~assets/svg/404_page/bg.svg");
});
const Foreground = loadable(() => {
  return import("~assets/svg/404_page/fg.svg");
});

export interface Template404PageStory {
  bodyRichText?: StoryblokFieldRichText;
  buttonLink?: Array<StoryblokBlokArray & ButtonLinkProps>;
  heading?: string;
}

export type Template404PageProps = GlobalPageProps<Template404PageStory>;

export { Head } from "~components/Head";
export default function Template404Page(props: Template404PageProps) {
  const { story } = usePageData<Template404PageProps>(props);

  const {
    bodyRichText,
    buttonLink: buttonWrapperBlokArray,
    heading,
  } = story || {};

  const [buttonLink] = buttonWrapperBlokArray || [];

  return (
    <LayoutPage {...props} headerBackground="background_charcoal">
      <LayoutSection
        maxWidth="unconstrained"
        background="background_charcoal"
        position="relative" /** <- ensure the background doesn't occlude the banner */
        overflow="hidden" /** <- ensure the background doesn't occlude the footer */
        isolation="isolate" /** <- ensure the background isn't impacted by the section's background color */
      >
        <Background
          className={clsx(
            global_backgroundImage,
            getSprinkles({
              zIndex: "-1",
            })
          )}
        />

        <VStack marginX="auto" marginY="spacing6">
          <Foreground className={clsx(getSprinkles({ height: "25vh" }))} />
          {heading && (
            <Box textAppearance="h1" as="h1" color="white" textAlign="center">
              {heading}
            </Box>
          )}
          {bodyRichText && (
            <StoryblokRichText
              color="white"
              textAlign="center"
              text={bodyRichText}
            />
          )}
          {buttonLink && <ButtonLink marginX="auto" {...buttonLink} />}
        </VStack>
      </LayoutSection>
    </LayoutPage>
  );
}

export const query = graphql`
  query Template404PageQuery(
    $id: String
    $lang: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...pageContentStoryblokEntry
    ...global
  }
`;
