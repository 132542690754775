import type { DropdownItemShape } from "~types/global.types";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToDropdownItem({
  fields,
  full_slug,
  field_title_string: pageTitle,
}: SbRelationsGraphQlNode): DropdownItemShape {
  const { pageMetadata } = fields || {};

  const { title: metaTitle } = pageMetadata || {};

  const title = pageTitle || metaTitle || "";

  return {
    label: title,
    value: title,
    link: full_slug,
  };
}
