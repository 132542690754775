import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

// import type { CardSubComponent } from "~components/Card";
import { CtaCardSmall } from "~components/CtaCardSmall";
import { EmbedWistia } from "~components/EmbedWistia";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { RichTextBlokResolver } from "~components/StoryblokRichText/RichTextBlokResolver";
import { Video } from "~components/Video";
import { VideoYoutube } from "~components/VideoYoutube";

import { GridStats } from "~sections/GridStats";
import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import type { EmbedWistiaProps } from "~components/EmbedWistia";
import type { NavListItemProps } from "~components/NavListItem";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { VideoProps } from "~components/Video";
import type { VideoYoutubeProps } from "~components/VideoYoutube";
import type { GridStatsProps } from "~sections/GridStats";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { GlobalPageProps } from "~types/global.types";
import type {
  AuthorEntry,
  CommonContentPageStoryProps,
  SbRelationsGraphQlNode,
  StoryblokBlok,
  StoryblokBlokArray,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export type CustomerStoryData = {
  latestEntriesOfType: { nodes: Array<SbRelationsGraphQlNode> };
  tagsItems?: { nodes: Array<SbRelationsGraphQlNode> };
};

export type CustomerStoryStory = CommonContentPageStoryProps & {
  author?: AuthorEntry;
  cover: StoryblokFieldMedia;
  coverVideo?: SectionHeaderProps["coverVideo"];
  isCoverVideoSticky?: SectionHeaderProps["isCoverVideoSticky"];
  date?: string;
  logo?: SvgLogoProps["svgLogo"];
  productsUsed?: RelationsCardsProps["content"];
  relatedCaseStudies?: Array<StoryblokBlok & RelationsCardsProps>;
  relatedProducts?: Array<StoryblokBlok & NavListItemProps>;
  stats?: StoryblokBlokArray & GridStatsProps["stats"];
  wideVideo?: Array<StoryblokBlok & VideoProps>;
  youtubeVideo?: Array<StoryblokBlok & VideoYoutubeProps>;
  wistiaVideo?: Array<StoryblokBlok & EmbedWistiaProps>;
};

export type TemplateCustomerStoryProps = GlobalPageProps<
  CustomerStoryStory,
  object,
  CustomerStoryData
>;

/** Template for a customer story / case study page. */
export { Head } from "~components/Head";

// const CARD_SUB_COMPONENTS: Array<CardSubComponent> = [
//   "title",
//   "prefix",
//   "description",
//   "image",
//   "logo",
// ];
export default function TemplateCustomerStory(
  props: TemplateCustomerStoryProps
) {
  const { pageContext, story } = usePageData<TemplateCustomerStoryProps>(props);

  const { title } =
    usePageMetadata({
      story,
    }) || {};

  const {
    logo,
    // relatedCaseStudies: relatedCaseStudiesBlokArray,
    // relatedProducts,
    stats,
    bodyRichText,
    wideVideo: wideVideoBlokArray,
    youtubeVideo: youtubeVideBlokArray,
    wistiaVideo: wistiaVideoBlokArray,
    cover,
    coverVideo,
    isCoverVideoSticky,
  } = story || {};

  const { breadcrumbs } = pageContext || {};
  const [wideVideo] = wideVideoBlokArray || [];

  /** Template may include a blok array containing an embedded youtube video */
  const [youtubeVideoBlok] = youtubeVideBlokArray || [];
  const [wistiaVideoBlok] = wistiaVideoBlokArray || [];

  return (
    <LayoutPage {...props} headerBackground="background_lavender">
      {/**
       * ----------------------------------------------------------------------------
       * Main page content
       * Wrapped in sidebar layout
       * -------------------------------------------------------------------------------
       */}

      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title}
        cover={cover}
        coverVideo={coverVideo}
        isCoverVideoSticky={isCoverVideoSticky}
        logo={logo}
      />

      <LayoutSidebar.Wrapper
        sidebarPositionDesktop="right"
        sidebarPositionMobile="top"
        isBorderRounded
      >
        <LayoutSidebar.Primary>
          {youtubeVideoBlok && (
            <StoryblokEditable {...youtubeVideoBlok}>
              <VideoYoutube {...youtubeVideoBlok} />
            </StoryblokEditable>
          )}

          {!youtubeVideoBlok && wideVideo && (
            <StoryblokEditable {...wideVideo}>
              <Video {...wideVideo} marginX="auto" />
            </StoryblokEditable>
          )}

          {wistiaVideoBlok && (
            <StoryblokEditable {...wistiaVideoBlok}>
              <EmbedWistia {...wistiaVideoBlok} />
            </StoryblokEditable>
          )}

          {bodyRichText && (
            <StoryblokRichText
              text={bodyRichText}
              defaultBlokResolver={RichTextBlokResolver}
            />
          )}
        </LayoutSidebar.Primary>

        <LayoutSidebar.Secondary isSidebarSticky>
          {stats && (
            <GridStats
              isAnimated={false}
              stats={stats}
              gridTemplateColumns={1}
              background="background_sand"
              size="sm"
              gap="spacing1"
            />
          )}

          <CtaCardSmall
            paddingY="spacing3"
            title="Ready to get started?"
            buttonText="Contact sales"
            conversionType="contact_sales"
          />
        </LayoutSidebar.Secondary>
      </LayoutSidebar.Wrapper>
    </LayoutPage>
  );
}

export const query = graphql`
  query CaseStudyQuery(
    $id: String
    $lang: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...pageContentStoryblokEntry
    ...global
  }
`;
