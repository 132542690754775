//  import  { ensureTrailingSlash } from "../url_utils/ensure_trailing_slash";
import type { StoryblokFieldLink } from "~types/storyblok.types";

/** Creates a link object with the necessary props for gatsby's link object. */
export const createInternalLink = (
  path?: string,
  state?: Record<string, unknown>
) => {
  if (path) {
    // const sanitisedPath = ensureTrailingSlash(path);

    const linkProps: StoryblokFieldLink = {
      linktype: "story",
      story: {
        url: path,
      },
    };

    if (state) {
      linkProps.state = state;
    }

    return linkProps;
  }

  return undefined;
};
