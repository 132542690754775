import React, { useMemo } from "react";

import { faDownload } from "@fortawesome/sharp-regular-svg-icons/faDownload";
import { graphql } from "gatsby";

import { reduceHeadingSizes } from "~styles/global/global.reduceHeadingSizes.css";

import { createInternalLink } from "~utils/link_utils/createInternalLink";

import { ButtonLink } from "~components/ButtonLink";
import { SanitizeHtmlWrapper } from "~components/SanitizeHtmlWrapper";
import { TableOfContents } from "~components/TableOfContents";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { AnchorJsContextProvider } from "~context/AnchorJsContext";

import { JobPostSidebarInfo } from "./JobPostSidebarInfo";

import type { ButtonLinkProps } from "~components/ButtonLink";
import type { GlobalPageProps } from "~types/global.types";
import type { GreenhouseBoardsApiGraphQLJobPost } from "~types/greenhouse.types";

const JOB_POST_APPLY_STRING = "Apply for this role";

export type JobPostData = {
  greenhouseJobPost: GreenhouseBoardsApiGraphQLJobPost;
};
export type JobPostPageContext = {
  pathToJobApplicationFormPage: string;
};

export type TemplateJobPostProps = GlobalPageProps<
  object,
  JobPostPageContext,
  JobPostData
>;

export { Head } from "~components/Head";
export default function TemplateJobPost(props: TemplateJobPostProps) {
  const { data, pageContext } = props;

  const { greenhouseJobPost } = data || {};
  const { pathToJobApplicationFormPage, breadcrumbs } = pageContext;
  const { content, title, departments, location } = greenhouseJobPost || {};

  const linkToJobApplicationForm = createInternalLink(
    pathToJobApplicationFormPage
  );

  const buttonLinkBlokArray: Array<ButtonLinkProps> = useMemo(() => {
    return [
      { link: linkToJobApplicationForm, children: JOB_POST_APPLY_STRING },
    ];
  }, [linkToJobApplicationForm]);

  return (
    <LayoutPage
      {...props}
      headerBackground="background_sand"
      programmaticPageTitle={title}
    >
      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title}
        buttonLink={buttonLinkBlokArray}
      />

      <AnchorJsContextProvider>
        <LayoutSidebar.Wrapper
          sidebarPositionDesktop="right"
          sidebarPositionMobile="bottom"
        >
          <LayoutSidebar.Primary>
            {/** Inject greenhouse job post html. sanitize it to normalize html markup. */}

            <SanitizeHtmlWrapper
              textAppearance="body_lg"
              display="grid"
              gap="gutterWidth"
              htmlString={content}
              data-testid="job-post-content"
              className={reduceHeadingSizes}
            />
          </LayoutSidebar.Primary>
          <LayoutSidebar.Secondary isSidebarSticky>
            {JOB_POST_APPLY_STRING && linkToJobApplicationForm && (
              <ButtonLink
                appearance="tertiary"
                link={linkToJobApplicationForm}
                iconLeft={faDownload}
              >
                {JOB_POST_APPLY_STRING}
              </ButtonLink>
            )}

            <JobPostSidebarInfo location={location} departments={departments} />
            <TableOfContents />
          </LayoutSidebar.Secondary>
        </LayoutSidebar.Wrapper>
      </AnchorJsContextProvider>
    </LayoutPage>
  );
}

export const query = graphql`
  query jobPostPageQuery(
    $id: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]! # $lang: String
  ) {
    ...global

    greenhouseJobPost(id: { eq: $id }) {
      content
      title
      location {
        name
      }
      offices {
        name
      }
      departments {
        name
      }
      fields {
        title
      }
    }
  }
`;
