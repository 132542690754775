import type { TransformedLinkPropsShape } from ".";
import type { StoryblokFieldLink } from "~types/storyblok.types";

/** Gets link props for an external link configured in storyblok. */
export function transformLinkPropsExternal(
  link: StoryblokFieldLink
): TransformedLinkPropsShape {
  return {
    target: "_blank",
    href: link.url || link.href,
    rel: "noreferrer",
    linkType: "externalLink",
  };
}
