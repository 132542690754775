import React from "react";

import { NavListItem } from "~components/NavListItem";

import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import type { TemplateJobPostProps } from ".";

const TITLE = "Job details";

type JobPostSidebarInfoProps = Pick<
  TemplateJobPostProps["data"]["greenhouseJobPost"],
  "departments" | "location"
>;

export function JobPostSidebarInfo({
  location: { name: location },
  departments: departmentsArray,
}: JobPostSidebarInfoProps) {
  const [department] = departmentsArray || [];
  const { name: departmentName } = department || {};

  return (
    <LayoutSidebar.Section title={TITLE} display="grid" gap="spacing1">
      {location && (
        <NavListItem
          title="Office"
          description={location}
          brandIcon="global_geographic"
        />
      )}
      {departmentName && (
        <NavListItem
          title="Team"
          description={departmentName}
          brandIcon="customers"
        />
      )}
    </LayoutSidebar.Section>
  );
}
