import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";

import type { BlogPostStory, TemplateBlogPostProps } from ".";

const ORIGIN = "https://truelayer.com";

export interface getBlogPostSchemaJsonArgs {
  pageContext: TemplateBlogPostProps["pageContext"];
  pagePath?: string;
  title?: string;
  story: BlogPostStory;
}

export function getBlogPostSchemaJson({
  story,
  pagePath,
  title,
}: getBlogPostSchemaJsonArgs) {
  const { cover, date } = story || {};

  const currentPagePath = createUrlPathFromArray([ORIGIN, pagePath]) || "";

  const sanitisedCurrentPagePath = removeDefaultLanguagePrefix(currentPagePath);

  if (!cover) return undefined;

  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
    },
    headline: title,
    image: typeof cover !== "undefined" ? cover.filename : undefined,
    url: sanitisedCurrentPagePath,
    author: {
      "@type": "Organization",
      name: "TrueLayer",
      url: ORIGIN,
    },
    publisher: {
      "@type": "Organization",
      name: "TrueLayer",
      logo: {
        "@type": "ImageObject",
        url: `${ORIGIN}/images/logo/TrueLayer.png`,
      },
    },
    datePublished: date,
    dateModified: date,
  };
}
