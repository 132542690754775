import type { VariantCardAppearanceEnum } from "~components/Card/styles.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

const SUB_COMPONENT_MAP: Record<
  VariantCardAppearanceEnum,
  GetSprinklesArgs["gridTemplateColumns"]
> = {
  customerStory: {
    desktop: 2,
    mobile: 1,
    tablet: 1,
  },
  event: {
    desktop: 2,
    mobile: 1,
    tablet: 2,
  },
  report: {
    desktop: 4,
    mobile: 1,
    tablet: 3,
  },
  blogPost: {
    desktop: 3,
    mobile: 1,
    tablet: 2,
  },
  generic: {
    desktop: 3,
    mobile: 1,
    tablet: 2,
  },
  video: {
    desktop: 3,
    mobile: 1,
    tablet: 2,
  },
  featured: {
    desktop: 1,
    mobile: 1,
    tablet: 1,
  },
  contactUs: {
    desktop: 1,
    mobile: 1,
    tablet: 1,
  },
  people: {
    desktop: 2,
    mobile: 2,
    tablet: 2,
  },
};

export const getCardGridTemplateColumns = (
  appearance: VariantCardAppearanceEnum
) => {
  return appearance
    ? SUB_COMPONENT_MAP[appearance]
    : SUB_COMPONENT_MAP.blogPost;
};
