import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import { transformLinkPropsAsset } from "./transformLinkPropsAsset";
import { transformLinkPropsEmail } from "./transformLinkPropsEmail";
import { transformLinkPropsExternal } from "./transformLinkPropsExternal";
import { transformLinkPropsInternal } from "./transformLinkPropsInternal";

import type { StoryblokFieldLink } from "~types/storyblok.types";

export interface TransformedLinkPropsShape {
  download?: string;
  href?: string;
  linkType?: string;
  rel?: string;
  state?: Record<string, unknown>;
  target?: string;
  to?: string;
}

export const getLinkProps = (
  link: StoryblokFieldLink | undefined
): TransformedLinkPropsShape => {
  if (!link || getIsInEditor()) {
    return {};
  }

  switch (link.linktype) {
    case "story":
      return transformLinkPropsInternal(link);
    case "url":
      return transformLinkPropsExternal(link);
    case "asset":
      return transformLinkPropsAsset(link);
    case "email":
      return transformLinkPropsEmail(link);
    default:
      return transformLinkPropsInternal(link);
  }
};
