import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

/**
 * Gets the item from DOM, adds a scrolling offset and performs a smooth scroll to it.
 */
export const smoothScrollToElement = (
  elementId: string,
  scrollMarginTop?: string
) => {
  if (!elementId.startsWith("#")) return;

  const idWithoutSymbol = elementId.replace("#", "");
  const element = document.getElementById(idWithoutSymbol);

  if (!element) return;

  const calcMargin = calc
    .add(
      vars.spacing.headerHeight,
      vars.spacing.spacing3,
      scrollMarginTop || "0px"
    )
    .toString();
  element.style.scrollMarginTop = calcMargin;

  const scrollOptions: ScrollIntoViewOptions = { behavior: "smooth" };

  element.scrollIntoView(scrollOptions);
};
