import type {
  LocalUiStringsType,
  StoryblokDatasourceEntryNode,
} from "~types/storyblok.types";

export const getLocalUiStrings = (
  stringNodes: StoryblokDatasourceEntryNode[]
): Partial<LocalUiStringsType> => {
  if (!stringNodes) {
    return {};
  }

  return stringNodes.reduce((acc, { name, value, dimension_value }) => {
    acc[name as keyof LocalUiStringsType] = dimension_value || value;

    return acc;
  }, {} as LocalUiStringsType);
};
