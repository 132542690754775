import { createInternalLink } from "~utils/link_utils/createInternalLink";

import { IconBrand } from "~components/IconBrand";

import type { TagLinkProps } from "~components/Tag/TagLink";
import type { StoryblokEntry } from "~types/storyblok.types";

/**
 * Transforms a {@link StoryblokEntry} into {@link TagLinkProps}
 *
 * Used for processing relations when in the Storyblok visual
 * editor, where they are queried directly from their REST API, rather
 * than from the GraphQL data-layer.
 */
export function transformStoryblokEntryApiToTag(
  storyblokEntry: StoryblokEntry
): TagLinkProps {
  const { content: entryContent, full_slug } = storyblokEntry || {};
  const { pageMetadata, brandIcon, title } = entryContent || {};
  const { title: metaTitle } = pageMetadata || {};

  const link = createInternalLink(full_slug);

  return {
    title: title || metaTitle,
    ornamentLeft: IconBrand({ icon: brandIcon, maxWidth: "spacing3" }),
    link,
  };
}
