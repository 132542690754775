import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";

import { StoryblokComponent } from "~components/StoryblokComponent";

import { LayoutPage } from "~sections/LayoutPage";
import { StoryblokSection } from "~sections/StoryblokSection";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { getHeroAndHeader } from "../../utils/getHeroAndHeader/_getHeroAndHeader";
import { getHeaderBackgroundColor } from "./_getHeaderBackgroundColor";

import type { BoxProps } from "~components/Box";
import type { GlobalPageProps } from "~types/global.types";
import type {
  SbRelationsGraphQlNode,
  StoryblokBlokArray,
  StoryblokChildren,
} from "~types/storyblok.types";

export type TemplateGenericPageStory = {
  children?: StoryblokChildren;
  sidebar?: StoryblokBlokArray;
  sidebarMaxWidth?: BoxProps["maxWidth"];
  positionDesktop?: "right" | "left";
  positionMobile?: "top" | "bottom";
  gapSize?: "sm" | "md" | "lg";
  removeTopBorder?: boolean;
};

export type TemplateGenericPageData = {
  paginationItems?: {
    nodes: Array<SbRelationsGraphQlNode>;
  };
  tagsItems?: { nodes: Array<SbRelationsGraphQlNode> };
};

export type TemplateGenericPageProps = GlobalPageProps<
  TemplateGenericPageStory,
  object,
  TemplateGenericPageData
>;

export { Head } from "~components/Head";
export default function TemplateGenericPage(props: TemplateGenericPageProps) {
  const { story } = usePageData<TemplateGenericPageProps>(props) || {};

  const {
    children,
    sidebar,
    sidebarMaxWidth,
    positionDesktop = "right",
    positionMobile = "bottom",
    gapSize,
    removeTopBorder,
  } = story || {};

  const { hero, header, content } = getHeroAndHeader(children);

  const headerBackground = getHeaderBackgroundColor({ children });

  const hasSidebar = sidebar && sidebar.length > 0;

  return (
    <LayoutPage headerBackground={headerBackground} {...props}>
      {hasSidebar ? (
        <>
          {hero && <StoryblokSection key={hero._uid} {...hero} />}
          {header && (
            <StoryblokSection
              key={header._uid}
              {...header}
              paddingTop="sectionPaddingY"
            />
          )}
          <LayoutSidebar.Wrapper
            sidebarPositionDesktop={positionDesktop}
            sidebarPositionMobile={positionMobile}
            gapSizeDesktop={gapSize}
            paddingTop="spacing5"
            justifyContent="space-between"
            removeTopBorder={removeTopBorder}
          >
            <LayoutSidebar.Primary>
              {content?.map((child) => {
                return <StoryblokSection key={child._uid} {...child} />;
              })}
            </LayoutSidebar.Primary>

            <LayoutSidebar.Secondary isSidebarSticky maxWidth={sidebarMaxWidth}>
              {sidebar?.map((child) => {
                return <StoryblokComponent key={child._uid} {...child} />;
              })}
            </LayoutSidebar.Secondary>
          </LayoutSidebar.Wrapper>
        </>
      ) : (
        children?.map((child) => {
          return <StoryblokSection key={child._uid} {...child} />;
        })
      )}
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateGenericPageQuery(
    $id: String
    $lang: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
    $tagsIds: [String]
    $paginationIds: [String]
    $sortBy: [StoryblokEntryFieldsEnum]
    $sortOrder: [SortOrderEnum]
  ) {
    ...global
    ...pageContentStoryblokEntry
    ...tagsItems
    ...paginationItems
  }
`;
