import { getLanguageFromSlug } from "../getLanguageFromSlug";

import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type {
  StoryblokEntry,
  StoryblokEntryAlternate,
  StoryblokEntryWithStringifiedContent,
} from "~types/storyblok.types";

export function getLanguageForStoryblokEntry(
  entry:
    | StoryblokEntry
    | StoryblokEntryWithStringifiedContent
    | StoryblokEntryAlternate
): EnabledLanguageIsoCode {
  const { full_slug } = entry || {};

  return getLanguageFromSlug(full_slug);
}
