import { createInternalLink } from "~utils/link_utils/createInternalLink";

import type { TagLinkProps } from "~components/Tag/TagLink";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export function transformStoryblokEntryNodeToTag({
  fields,
  full_slug,
  field_title_string: title,
}: SbRelationsGraphQlNode): TagLinkProps {
  const link = createInternalLink(full_slug);

  const {
    pageMetadata,
    brandIcon: _brandIcon,
    cardColor: _cardColor,
    linkToExternalPage: _linkToExternalPage,
    ...restFields
  } = fields || {};

  return {
    link,
    title: title || pageMetadata?.title,
    ...restFields,
  };
}
