import React, { useState } from "react";

import { graphql } from "gatsby";
import { Configure, InstantSearch } from "react-instantsearch";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { searchClient } from "~sections/AlgoliaSearch/algoliaSearchClient";
import { JobSearchHero } from "~sections/JobSearchHero";
import { JobSearchResults } from "~sections/JobSearchResults";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSection } from "~sections/LayoutSection";

import { ALGOLIA_JOB_SEARCH_INDEX } from "~config/ALGOLIA.config";
import { JobSearchContextProvider } from "~context/JobSearchContext";

import type { ImageBackgroundProps } from "~components/ImageBackground";
import type { GlobalPageProps } from "~types/global.types";
import type {
  CommonPageStoryProps,
  StoryblokBlok,
} from "~types/storyblok.types";

export type SearchJobsStory = CommonPageStoryProps & {
  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;

  heroBackgroundImage?: Array<StoryblokBlok & ImageBackgroundProps>;
};
export type TemplateJobSearchProps = GlobalPageProps<SearchJobsStory>;

export { Head } from "~components/Head";
export default function TemplateJobSearch(props: TemplateJobSearchProps) {
  const { story } = usePageData<TemplateJobSearchProps>(props);

  // ensuring backwards compatibility for pages without new title field valued
  const { title } = story || {};
  const { title: metaTitle } = usePageMetadata({ story }) || {};
  const pageTitle = title || metaTitle;

  const [roleCount, setRoleCount] = useState(0);

  return (
    <LayoutPage {...props}>
      <InstantSearch
        searchClient={searchClient}
        indexName={ALGOLIA_JOB_SEARCH_INDEX}
      >
        <Configure hitsPerPage={999} />

        <JobSearchContextProvider>
          {/** Page header & search controls */}

          <LayoutSection background="background_white">
            {pageTitle && (
              <JobSearchHero roleCount={roleCount} title={pageTitle} />
            )}
          </LayoutSection>

          {/** Search results */}

          <LayoutSection isBorderRounded background="background_sand">
            <JobSearchResults roleCountCallback={setRoleCount} />
          </LayoutSection>
        </JobSearchContextProvider>
      </InstantSearch>
    </LayoutPage>
  );
}

export const query = graphql`
  fragment templateJobSearchStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      # assets {
      #   ...fieldsAsset
      # }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query templateJobSearchQuery(
    $id: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...templateJobSearchStory
    ...global
  }
`;
