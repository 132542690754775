import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";

const CURRENCY_MAP: Partial<Record<EnabledLanguageIsoCode, string>> = {
  "en-gb": "GBP",
  "de-de": "EUR",
  "es-es": "EUR",
  "fr-fr": "EUR",
  "en-ie": "EUR",
  "it-it": "EUR",
  "en-se": "SEK",
};

interface FormatCurrencyStringArgs {
  amount: number | string;
  lang?: EnabledLanguageIsoCode;
  currencyCode?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

export const formatCurrencyString = ({
  amount,
  lang = "en-gb",
  currencyCode,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}: FormatCurrencyStringArgs) => {
  if (!amount) return null;

  const formatter = new Intl.NumberFormat(lang, {
    style: "currency",
    currency: currencyCode || CURRENCY_MAP[lang],
    maximumFractionDigits,
    minimumFractionDigits,
  });

  const floatAmount = typeof amount === "string" ? parseFloat(amount) : amount;

  return formatter.format(floatAmount);
};
