import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { CtaCardHubspotFormDialog } from "~components/CtaCardHubspotFormDialog";
import { EventDateLocation } from "~components/EventDateLocation";
import { StoryblokComponent } from "~components/StoryblokComponent";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import type { SectionHeaderProps } from "../../components/SectionHeader/index";
import type { EmbedWistiaProps } from "~components/EmbedWistia";
import type { EventDateLocationProps } from "~components/EventDateLocation";
import type { VideoYoutubeProps } from "~components/VideoYoutube";
import type { GlobalPageProps } from "~types/global.types";
import type {
  CommonPageStoryProps,
  StoryblokBlok,
  StoryblokChildren,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export type LandingPageEventData = {
  story: CommonPageStoryProps;
};

export type LandingPageEventStory = {
  children?: StoryblokChildren;
  cover?: StoryblokFieldMedia;
  hubspotFormCta?: string;
  hubspotFormDescription?: string;
  hubspotFormId?: string;
  hubspotFormTitle?: string;
  youtubeVideo?: Array<StoryblokBlok & VideoYoutubeProps>;
  wistiaVideo?: Array<StoryblokBlok & EmbedWistiaProps>;
  eventDateLocation?: Array<StoryblokBlok & EventDateLocationProps>;
  heroCTA?: SectionHeaderProps["buttonLink"];
};

export type TemplateLandingPageEventProps = GlobalPageProps<
  LandingPageEventStory,
  object,
  LandingPageEventData
>;

export { Head } from "~components/Head";
export default function TemplateLandingPageEvent(
  props: TemplateLandingPageEventProps
) {
  const { story, pageContext } =
    usePageData<TemplateLandingPageEventProps>(props);

  const { title } = usePageMetadata({ story }) || {};
  const { breadcrumbs } = pageContext || {};

  const {
    children,
    hubspotFormCta,
    hubspotFormDescription,
    hubspotFormId,
    hubspotFormTitle,
    // relatedItems: relatedItemsBlokArray,
    eventDateLocation: eventDateLocationArray,
    heroCTA,
  } = story;

  const [eventDateLocation] = eventDateLocationArray ?? [];
  const { date, location } = eventDateLocation ?? {};

  const shouldRenderForm =
    hubspotFormId &&
    hubspotFormTitle &&
    hubspotFormDescription &&
    hubspotFormCta;

  return (
    <LayoutPage headerBackground="background_lavender" {...props}>
      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title}
        buttonLink={heroCTA}
      />

      {/**
       * ----------------------------------------------------------------------------
       * Main page content
       * Wrapped in sidebar layout
       * -------------------------------------------------------------------------------
       */}

      <LayoutSidebar.Wrapper
        sidebarPositionDesktop="right"
        sidebarPositionMobile="bottom"
      >
        <LayoutSidebar.Primary
          display="flex"
          flexDirection="column"
          gap="sectionRowGap"
        >
          {eventDateLocation && <EventDateLocation {...{ date, location }} />}

          {Array.isArray(children) && children.map(StoryblokComponent)}
        </LayoutSidebar.Primary>

        {/**
         * --------------------------------------------
         * Sidebar content
         * -----------------------------------------------
         */}

        <LayoutSidebar.Secondary isSidebarSticky>
          {/* Form "download now" CTA button */}
          {shouldRenderForm && (
            <CtaCardHubspotFormDialog
              title={hubspotFormTitle}
              text={hubspotFormDescription}
              formId={hubspotFormId}
              buttonText={hubspotFormCta}
              conversionType="content_webinar"
            />
          )}
        </LayoutSidebar.Secondary>
      </LayoutSidebar.Wrapper>
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateLandingPageEventQuery(
    $id: String
    $languageRegex: String
    $lang: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
    $tagsIds: [String]
  ) {
    ...pageContentStoryblokEntry
    ...global
    ...tagsItems
  }
`;
