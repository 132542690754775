import { StoryblokError } from "../../errors/errors";

import type { StoryblokEntryWithStringifiedContent } from "~types/storyblok.types";

/** @deprecated this should be optimised to run once and made available via React context
 *
 * @todo [MW-1311] Stop parsing storyblok entry repeatedly at different levels
 * - src/sections/LayoutPage/use_page_layout_wrapper_storyblok_data.ts
 * - src/hooks/use_page_data/use_page_data.ts
 * - src/components/Head/index.tsx
 *
 */
export function parseStoryblokEntry(
  key: string,
  entry: Partial<StoryblokEntryWithStringifiedContent>
) {
  if (!entry || !entry.content) {
    console.warn("Missing data for", key);
    throw new StoryblokError(`Missing data for ${key}`);
  }

  //  component might be coming from the storyblok API in editor
  //  directly in which case it will be already parsed
  if (typeof entry.content === "object") {
    return entry.content;
  }

  try {
    return {
      ...JSON.parse(entry.content),
      ...entry.fields,
    };
  } catch (e) {
    console.warn("Error parsing data for", key);
    throw new StoryblokError(`Error parsing data for ${key}`);
  }
}
