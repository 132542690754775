import type { TransformedLinkPropsShape } from ".";
import type { StoryblokFieldLink } from "~types/storyblok.types";

/** Gets link props for an email link configured in storyblok. */
export function transformLinkPropsEmail(
  link: StoryblokFieldLink
): TransformedLinkPropsShape {
  const emailAddress = link.url || link.href;
  const mailToLink = `mailto:${emailAddress}`;

  return {
    target: "_blank",
    href: mailToLink,
    linkType: "emailLink",
  };
}
