import React, { useMemo } from "react";

import { graphql } from "gatsby";

import { reduceHeadingSizes } from "~styles/global/global.reduceHeadingSizes.css";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { getLocalUiStrings } from "~utils/localUiStrings/getLocalUiStrings";
import { transformStoryblokEntryNodeToCard } from "~utils/transformers/transformStoryblokEntryNodeToCard";

import { Box } from "~components/Box";
import { CtaCardSmall } from "~components/CtaCardSmall";
import { HorizontalDivider } from "~components/HorizontalDivider";
import { JsonLd } from "~components/JsonLd";
import { SectionHeader } from "~components/SectionHeader";
import { StoryblokComponent } from "~components/StoryblokComponent";
import { StoryblokRichText } from "~components/StoryblokRichText";
import { RichTextBlokResolver } from "~components/StoryblokRichText/RichTextBlokResolver";

import { GridCards } from "~sections/GridCards";
import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { BlogPostCategories } from "../../components/BlogCategories/BlogPostCategories";
import { getBlogPostSchemaJson } from "./_getBlogPostSchemaJson";

import type { CtaCardProps } from "~components/CtaCard";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { GlobalPageProps } from "~types/global.types";
import type {
  AuthorEntry,
  CommonPageStoryProps,
  SbRelationsGraphQlNode,
  StoryblokBlok,
  StoryblokBlokArray,
  StoryblokDatasourceEntryNode,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export type BlogPostData = {
  latestEntriesOfType: { nodes: Array<SbRelationsGraphQlNode> };
  tagsItems?: { nodes: Array<SbRelationsGraphQlNode> };
  localUiStrings: {
    nodes: Array<StoryblokDatasourceEntryNode>;
  };
};
export type BlogPostStory = CommonPageStoryProps & {
  relatedPosts?: Array<StoryblokBlok & RelationsCardsProps>;
  categories?: Array<StoryblokBlok & RelationsCardsProps>;
  cover?: StoryblokFieldMedia;
  coverVideo?: SectionHeaderProps["coverVideo"];
  isCoverVideoSticky?: SectionHeaderProps["isCoverVideoSticky"];
  date?: string;
  bloks?: StoryblokBlokArray;
  author?: AuthorEntry;
  cta: Array<StoryblokBlok & CtaCardProps>;
  children?: Array<StoryblokBlok>;

  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;
};
export type TemplateBlogPostProps = GlobalPageProps<
  BlogPostStory,
  object,
  BlogPostData
>;

export { Head } from "~components/Head";

export default function TemplateBlogPost(props: TemplateBlogPostProps) {
  const { pageContext, data, story, pagePath } =
    usePageData<TemplateBlogPostProps>(props);

  const {
    categoriesToExplore,
    latestArticles,
    blogSidebarCardCta,
    blogSidebarCardText,
    blogSidebarCardTitle,
  } = getLocalUiStrings(data.localUiStrings?.nodes);

  // ensuring backwards compatibility for pages without new title field valued
  const { title: metaTitle } =
    usePageMetadata({
      story,
    }) || {};

  const {
    title,
    cover,
    coverVideo,
    isCoverVideoSticky,
    date,
    /** @todo rename this field after migration has completed */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-ignore */
    content,
    author,
    cta: ctaBlokArray,
    // relatedPosts: relatedPostsBlokArray,
  } = story || {};

  const { latestEntriesOfType } = data || {};

  const { breadcrumbs } = pageContext || {};

  /** Get schema markup metadata for search engines. https://schema.org/blogposting */
  const blogPostingSchemaMarkup = useMemo(() => {
    if (!story) return undefined;

    return getBlogPostSchemaJson({
      pageContext,
      story,
      title: metaTitle,
      pagePath,
    });
  }, [pageContext, pagePath, story, metaTitle]);

  return (
    <LayoutPage {...props} headerBackground="background_sand">
      <JsonLd data={blogPostingSchemaMarkup} />

      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={title || metaTitle}
        author={author}
        authorDate={date}
        cover={cover}
        coverVideo={coverVideo}
        isCoverVideoSticky={isCoverVideoSticky}
        background="background_sand"
      />

      <LayoutSidebar.Wrapper
        sidebarPositionDesktop="right"
        sidebarPositionMobile="bottom"
        gapSizeDesktop="lg"
        isBorderRounded
      >
        <LayoutSidebar.Primary>
          {/* Blog post body */}
          {content && (
            <StoryblokRichText
              text={content}
              defaultBlokResolver={RichTextBlokResolver}
              className={reduceHeadingSizes}
            />
          )}
          <Box paddingY="spacing5">
            {Array.isArray(ctaBlokArray) &&
              ctaBlokArray.map((item) => {
                return <StoryblokComponent key={item._uid} {...item} />;
              })}
          </Box>
          <HorizontalDivider marginY="sectionRowGap" />

          {latestEntriesOfType && (
            <>
              <SectionHeader prefix={latestArticles} />
              <GridCards
                gridTemplateColumns={{
                  mobile: 1,
                  tablet: 1,
                  desktop: 3,
                }}
                cards={latestEntriesOfType?.nodes.map(
                  transformStoryblokEntryNodeToCard
                )}
                rowGap="spacing1"
                appearance="blogPost"
                subComponents={["title", "date", "image"]}
              />
            </>
          )}
          <HorizontalDivider marginY="sectionRowGap" />
          <SectionHeader prefix={categoriesToExplore} />
          <BlogPostCategories />
        </LayoutSidebar.Primary>

        <LayoutSidebar.Secondary isSidebarSticky>
          <CtaCardSmall
            paddingY="spacing3"
            title={blogSidebarCardTitle}
            text={blogSidebarCardText}
            buttonText={blogSidebarCardCta}
            conversionType="contact_sales"
          />
        </LayoutSidebar.Secondary>
      </LayoutSidebar.Wrapper>
    </LayoutPage>
  );
}

export const query = graphql`
  query blogPostQuery(
    $id: String
    $lang: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]!
    $tagsIds: [String]
    # $relationsTags: [String]!

    $templateBlogPost: String
    $latestEntriesOfTypeIds: [String]
    $localUiStringsIds: [String]
  ) {
    ...global
    ...pageContentStoryblokEntry
    ...graphQlFragmentLatestEntriesOfType
    ...tagsItems
    ...localUiStrings

    author: storyblokEntry(uuid: { eq: $templateBlogPost }) {
      content
    }
  }
`;
