/** Util to remove leading/trailing slashes */
import { ensureLeadingSlash } from "../ensureLeadingSlash";
import { ensureTrailingSlash } from "../ensureTrailingSlash";

interface CreateUrlFromPathArrayOptions {
  shouldHaveTrailingSlash?: boolean;
  shouldHaveLeadingSlash?: boolean;
}

const cleanPathSegment = (rawSegment?: string) => {
  return rawSegment ? rawSegment.replace(/^\/|\/$/g, "") : rawSegment;
};

export const createUrlPathFromArray = (
  array: Array<string | undefined>,
  {
    shouldHaveTrailingSlash,
    shouldHaveLeadingSlash,
  }: CreateUrlFromPathArrayOptions = {}
): string => {
  if (array.length > 0) {
    const sanitisedArray = array.map(cleanPathSegment).filter(Boolean);

    let URLPath = sanitisedArray.join("/");

    if (shouldHaveTrailingSlash) {
      URLPath = ensureTrailingSlash(URLPath);
    }
    if (shouldHaveLeadingSlash) {
      URLPath = ensureLeadingSlash(URLPath);
    }

    return URLPath;
  }

  return "";
};
