import format from "date-fns/format";

import { getTimezoneOffsetDate } from "./get_timezone_offset_date";

const DATE_FORMAT_LONG = "d LLLL yyyy";
const DATE_FORMAT_SHORT = "d LLL yyyy";

export const formatDate = (utcDateString?: string, long = false) => {
  if (!utcDateString) {
    return "";
  }

  // Use js Date object with time offset vs environment timezone
  const offsetDate = getTimezoneOffsetDate(utcDateString);

  return long
    ? format(offsetDate, DATE_FORMAT_LONG)
    : format(offsetDate, DATE_FORMAT_SHORT);
};
