/**
 * Util to remove default language prefix from paths.
 * The `en-gb` version of the website is not served from a subfolder,
 * as it is considered the "default" version of our site.
 *
 * e.g. `en-gb/payments` -> `payments`
 *
 * Note: `path` may be be an empty string, which will evaluate to falsy,
 * in which case it is returned unchanged, (`getLinkProps` depends on this behavior.)
 */
export const removeDefaultLanguagePrefix = (path: string) => {
  return path ? path.replace("en-gb/", "") : path;
};
