import React from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";
import { usePageMetadata } from "~hooks/use_page_metadata/use_page_metadata";

import { Box } from "~components/Box";
import { ContentListItems } from "~components/ContentListItems";
import { Pagination } from "~components/Pagination";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSection } from "~sections/LayoutSection";

import type { GlobalPageProps } from "~types/global.types";
import type { SbRelationsGraphQlNode } from "~types/storyblok.types";

export interface LegalDoc {
  field_description_string: string;
  field_legalHubDocumentCategory_string: string;
  field_title_string: string;
  full_slug: string;
}
export interface CategorySection {
  documentCategory: string;
  id: string;
  items: Array<LegalDoc>;
  title: string;
}

export type DocumentListData = {
  paginationItems: { nodes: Array<SbRelationsGraphQlNode> };
};
export type DocumentListPageContext = {
  currentPage: number;
  pageCount: number;
  paginationIds: Array<string>;
};
export type TemplateDocumentListProps = GlobalPageProps<
  object,
  DocumentListPageContext,
  DocumentListData
>;
export { Head } from "~components/Head";
export default function TemplateDocumentList(props: TemplateDocumentListProps) {
  const { story, pageContext, data, pagePath } =
    usePageData<TemplateDocumentListProps>(props);

  const { currentPage, pageCount, breadcrumbs } = pageContext;

  // ensuring backwards compatibility for pages without new title field valued
  const { title } = story || {};
  const { title: metaTitle } = usePageMetadata({ story }) || {};
  const pageTitle = title || metaTitle;
  const paginationItems = data.paginationItems?.nodes;

  return (
    <LayoutPage headerBackground="background_sand" {...props}>
      <HeroContentPage
        breadcrumbs={breadcrumbs}
        title={pageTitle}
        background="background_sand"
      />

      <LayoutSection>
        <Box
          display="grid"
          gap="gutterWidth"
          gridTemplateColumns={{
            mobile: 1,
            tablet: 2,
            desktop: 4,
          }}
        >
          <ContentListItems
            cardProps={{
              subComponents: ["title", "icon", "date"],
            }}
            cardAppearance="report"
            items={paginationItems}
          />
        </Box>

        {pagePath && (
          <Pagination
            marginY="spacing10"
            basePath={pagePath}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        )}
      </LayoutSection>
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateDocumentListQuery(
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]!
    # $relationsTags: [String]!

    $paginationIds: [String]
    $id: String
    $lang: String
    $languageRegex: String
  ) {
    ...pageContentStoryblokEntry
    ...global

    paginationItems: allStoryblokEntry(
      filter: { id: { in: $paginationIds } }
      sort: { fields: fields___date, order: DESC }
    ) {
      nodes {
        ...fragmentStoryblokEntryListItemFields
      }
    }
  }
`;
