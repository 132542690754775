import React, { useMemo } from "react";

import { graphql } from "gatsby";

import { usePageData } from "~hooks/use_page_data/use_page_data";

import { getLocalUiStrings } from "~utils/localUiStrings/getLocalUiStrings";
import { transformStoryblokEntryNodeToDropdownItem } from "~utils/transformers/transformStoryblokEntryNodeToDropdownItem";

import { Box } from "~components/Box";
import { type VariantCardAppearanceEnum } from "~components/Card/styles.css";
import { ContentListItems } from "~components/ContentListItems";
import { Pagination } from "~components/Pagination";
import { SelectSingle } from "~components/Select/SelectSingle";

import { HeroContentPage } from "~sections/HeroContentPage";
import { LayoutPage } from "~sections/LayoutPage";
import { StoryblokSection } from "~sections/StoryblokSection";
import { LayoutSidebar } from "~sections/layout/LayoutSidebar";

import { getCardGridTemplateColumns } from "./getCardGridTemplateColumns";
import { getCardSubComponents } from "./getCardSubComponents";

import type { IconBrandEnum } from "~components/IconBrand";
import type { RelationsCardsProps } from "~sections/RelationsCards";
import type { GlobalPageProps } from "~types/global.types";
import type {
  SbRelationsGraphQlNode,
  StoryblokChildren,
  StoryblokDatasourceEntryNode,
} from "~types/storyblok.types";

export type ContentListData = {
  paginationItems: {
    nodes: Array<SbRelationsGraphQlNode>;
  };
  tagsItems: {
    nodes: Array<SbRelationsGraphQlNode>;
  };
  localUiStrings: {
    nodes: Array<StoryblokDatasourceEntryNode>;
  };
};
export type ContentListStory = {
  // temporarily not mandatory for backwards compatibility with metaTitle
  title?: string;
  cardAppearance?: VariantCardAppearanceEnum;
  brandIcon?: IconBrandEnum;
  sortBy?: "fields___date";
  featuredContent?: Array<RelationsCardsProps>;
  childrenBefore?: StoryblokChildren;
  childrenAfter?: StoryblokChildren;
};

export type ContentListPageContext = {
  currentPage: number;
  sortBy: string;
  sortOrder: "ASC" | "DESC";
  pageCount: number;
  paginationIds: Array<string>;
};
export type TemplateContentListProps = GlobalPageProps<
  ContentListStory,
  ContentListPageContext,
  ContentListData
>;

const paginationWrapperId = "articles";

/**
 * Renders a list of content as `Card` components, with a sidebar containing links to
 * content. Used for blogs, reports, resource hub, etc.
 */
export { Head } from "~components/Head";
export default function TemplateContentListPage(
  props: TemplateContentListProps
) {
  const { story, pageContext, data, pagePath } =
    usePageData<TemplateContentListProps>(props);

  const paginationItems = data.paginationItems?.nodes;
  const tagsItems = data.tagsItems?.nodes;
  const localUiStrings = getLocalUiStrings(data.localUiStrings?.nodes);

  const { currentPage, pageCount, breadcrumbs } = pageContext;

  const {
    title,
    pageMetadata,
    cardAppearance = "blogPost",
    featuredContent,
    childrenBefore,
    childrenAfter,
  } = story || {};

  const hasFeaturedContent = featuredContent && featuredContent?.length > 0;

  // ensuring backwards compatibility for pages without new title field valued
  const { title: metaTitle } = pageMetadata || {};
  const pageTitle = title || metaTitle;

  const featuredBreadcrumbs = [
    ...(breadcrumbs ?? []),
    { link: undefined, title: pageTitle ?? "" },
  ];

  const dropdownTagProps = useMemo(() => {
    if (!Array.isArray(tagsItems)) return [];

    return tagsItems.map(transformStoryblokEntryNodeToDropdownItem);
  }, [tagsItems]);

  const headerAndHeroBackground = "background_sand";
  const paginationBackground = "background_white";

  return (
    <LayoutPage {...props} headerBackground={headerAndHeroBackground}>
      <HeroContentPage
        breadcrumbs={hasFeaturedContent ? featuredBreadcrumbs : breadcrumbs}
        title={hasFeaturedContent ? undefined : pageTitle}
        featuredContent={featuredContent}
        background={headerAndHeroBackground}
      />

      {childrenBefore?.map((child) => {
        return <StoryblokSection key={child._uid} {...child} />;
      })}

      <LayoutSidebar.Wrapper
        sectionId={paginationWrapperId}
        gapSizeDesktop="sm"
        isBorderRounded
        sidebarPositionDesktop="right"
        sidebarPositionMobile="top"
        background={paginationBackground}
      >
        <LayoutSidebar.Primary>
          {dropdownTagProps.length >= 2 && (
            <Box
              display="flex"
              alignItems="center"
              marginBottom="spacing3"
              gap="spacing2"
            >
              <Box fontWeight="bold">{localUiStrings.categoryFilterLabel}</Box>
              <SelectSingle
                display="flex"
                alignItems="center"
                placeholder={pageTitle}
                items={dropdownTagProps}
                inputAppearance="rounded"
                id="category-filter"
                name="category-filter"
              />
            </Box>
          )}

          <Box
            display="grid"
            justifyItems="center"
            gap="gutterWidth"
            gridTemplateColumns={getCardGridTemplateColumns(cardAppearance)}
          >
            <ContentListItems
              cardAppearance={cardAppearance}
              cardProps={{
                subComponents: getCardSubComponents(cardAppearance),
              }}
              items={paginationItems}
            />
          </Box>
          {pagePath && (
            <Pagination
              marginY="spacing10"
              basePath={pagePath}
              pageCount={pageCount}
              currentPage={currentPage}
              anchorLinkId={`#${paginationWrapperId}`}
            />
          )}
        </LayoutSidebar.Primary>
      </LayoutSidebar.Wrapper>
      {childrenAfter?.map((child) => {
        return <StoryblokSection key={child._uid} {...child} />;
      })}
    </LayoutPage>
  );
}

export const query = graphql`
  query TemplateContentListPageQuery(
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]!
    # $relationsTags: [String]!

    $id: String
    $paginationIds: [String]
    $tagsIds: [String]
    $lang: String
    $languageRegex: String
    $sortBy: [StoryblokEntryFieldsEnum]
    $sortOrder: [SortOrderEnum]
    $localUiStringsIds: [String]
  ) {
    ...pageContentStoryblokEntry
    ...global
    ...tagsItems
    ...paginationItems
    ...localUiStrings
  }
`;
