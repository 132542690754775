import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";

const OPTIONS = {
  year: "numeric",
  month: "short",
  day: "numeric",
} as const;

export const getFakeDateString = (
  offsetDays: number,
  lang: EnabledLanguageIsoCode = "en-gb"
) => {
  const fakeDate = new Date(Date.now() - offsetDays * 24 * 60 * 60 * 1000);

  return new Intl.DateTimeFormat(lang, OPTIONS).format(fakeDate);
};
