import React from "react";

import { graphql } from "gatsby";

import { FormGreenhouseJobApplication } from "~components/FormGreenhouseJobApplication";
import { SectionHeader } from "~components/SectionHeader";

import { LayoutPage } from "~sections/LayoutPage";
import { LayoutSection } from "~sections/LayoutSection";

import type { GlobalPageProps } from "~types/global.types";

interface TemplateJobApplicationFormData {
  greenhouseJobPost?: {
    title: string;
    departments?: Array<{
      name: string;
    }>;
  };
}
interface TemplateJobApplicationPageContext {
  pathToPageJobPost: string;
  publicJobId: number;
}

export type TemplateJobApplicationFormProps = GlobalPageProps<
  object,
  TemplateJobApplicationPageContext,
  TemplateJobApplicationFormData
>;

export { Head } from "~components/Head";
export default function TemplateJobApplicationForm(
  props: TemplateJobApplicationFormProps
) {
  const { pageContext, data } = props;

  const { publicJobId, breadcrumbs } = pageContext || {};

  const { greenhouseJobPost } = data || {};
  const { title } = greenhouseJobPost || {};

  return (
    <LayoutPage
      {...props}
      headerBackground="background_sand"
      programmaticPageTitle={title}
    >
      <LayoutSection background="background_sand">
        <SectionHeader
          breadcrumbs={breadcrumbs}
          fontFamily="formula"
          isCentered
          prefix="Applying for"
          title={title}
        />
      </LayoutSection>

      <LayoutSection maxWidth="gridSpan6">
        {publicJobId && (
          <FormGreenhouseJobApplication publicJobId={publicJobId} />
        )}
      </LayoutSection>
    </LayoutPage>
  );
}

export const query = graphql`
  query jobApplicationPageQuery(
    $id: String
    $languageRegex: String
    $relationsCards: [String]!
    $relationsRecommendedContent: [String]!
    $relationsLinkList: [String]!
    $CarouselRelationsItem: [String]! # $relationsTags: [String]!
  ) {
    ...global
    greenhouseJobPost(id: { eq: $id }) {
      title
    }
  }
`;
