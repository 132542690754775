import {
  CONVERSION_TYPES,
  type ConversionEvent,
} from "~config/CONVERSION_TYPES.config";

import type { WindowWithGtmDataLayer } from "~types/gtm.types";

export type ConversionComponent =
  | "CtaCard"
  | "CtaCardSmall"
  | "CtaNewsletter"
  | "CtaCardHubspotFormDialog"
  | "CtaFooter"
  | "FooterNewsletter"
  | "ContentSidebarHubspotForm"
  | "RegistrationFormHubspot"
  | "FormHubspot"
  | "test_component";

export interface DispatchConversionEventArgs {
  type: ConversionEvent;
  component: ConversionComponent;
  cta: string | undefined;
}

export const getDataLayer = ():
  | WindowWithGtmDataLayer["dataLayer"]
  | undefined => {
  if (typeof window === "undefined") return undefined;
  const { dataLayer } = window as WindowWithGtmDataLayer;

  return dataLayer;
};

/** Send a conversion events to Google Tag Manager */
export function dispatchConversionEvent({
  type,
  component,
  cta,
}: DispatchConversionEventArgs): boolean {
  if (!type || !CONVERSION_TYPES.includes(type)) return false;

  const dataLayer = getDataLayer();
  if (!dataLayer) return false;

  dataLayer.push({
    event: type,
    component,
    cta,
  });

  return true;
}
